import '../scss/main.scss'

//import riot from 'riot'
//import '../tag/tags.js'

import jquery from 'jquery'
window.$ = jquery
window.jQuery = jquery

//import '../../node_modules/jquery/dist/jquery.js'

import popper from 'popper.js'

//import bootstrap from 'bootstrap'
import '../../node_modules/bootstrap/dist/js/bootstrap.js'
import '../../node_modules/bootstrap/dist/css/bootstrap.css'

import '../../node_modules/font-awesome/css/font-awesome.css'


window.moment = require('moment');
require('moment/locale/ja.js')
require('tempusdominus-bootstrap-4');
require('tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css');
//import 'fullcalendar';
//import 'moment-timezone';



//require('moment/locale/ja.js')
//window.$2.fn.datetimepicker = require('tempusdominus-bootstrap-4');

//import 'moment'
//import 'tempusdominus-bootstrap-4'



//import moment from '../../node_modules/moment/moment.js'
//import '../../node_modules/moment/locale/ja.js'



//import '../../node_modules/tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.js'
//import '../../node_modules/tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css'
//import '../libs/Bootstrap-4-Date-Time-Picker/locale/ja.js'

//import '../libs/bootstrap-datetimepicker/bootstrap-datetimepicker.js'
//import '../libs/bootstrap-datetimepicker/bootstrap-datetimepicker.css'
//import '../libs/bootstrap-datetimepicker/locales/ja.js'


//import '../libs/Bootstrap-4-Date-Time-Picker/tempusdominus-bootstrap-4.js'
//import '../libs/Bootstrap-4-Date-Time-Picker/tempusdominus-bootstrap-4.css'


//import { auth } from './auth'
//import { dataSources } from './dataSources'
//import { controls } from './controls'

//import '../libs/DataTables/datatables.css'
//import '../libs/DataTables/datatables.js'

//import '../print.html'

import riot from 'riot'
import route from 'riot-route'

//var obs = riot.observable();

import '../tags' // タグファイルを読み込む

//import feather from '../libs/feather'

//riot.mount('*')




//*
//riot.mount('app-param');

riot.mount('app-config')
riot.mount('app-utility')

riot.mount('app-auth')

riot.mount('app-header')
riot.mount('app-footer')

riot.mount('flash')
riot.mount('dimmer')




//*/



/*
route('/', function() {

  //firebase.auth().onAuthStateChanged(function(user) {
  var authinfo = localStorage.getItem('auth')

  console.log('authinfo');console.log(authinfo)

  var user = true;
  if (user) {
    riot.mount('content', 'app-mapview-container');
  } else {
    route('/login');
  }

});

route('/login', function(){
  riot.mount('content', 'app-auth', {type: 'login'});
})

route.start(true);
//*/
